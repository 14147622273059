.push-notification>.ant-card {
    background-color: #f5f5f5;
    border: none;
    border-radius: 15px;
    margin-bottom: 15px;
    padding: 5px 10px;
}

.title-label {
    font-size: 14px;
    font-weight: 500;
    color: gray;
}

.label {
    font-size: 17px;
    font-weight: 500;
    color: #0f0f0f;
    margin-bottom: 20px;
}

.mobile-container {
    background-color: #D3D3D3;
    border-top-right-radius: 35px;
    border-top-left-radius: 35px;
    text-align: -webkit-center;
    padding: 18px 10px;
}

.noti-container {
    height: 90;
    background-color: white;
    border-radius: 15px;
    margin-top: 15px;
    text-align: left;
    padding: 13px;
}

.mobile-status-bar {
    height: 35px;
    width: 150px;
    background-color: black;
    border-radius: 20px;
}

.content-style-initial {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.content-style-expand {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
}




/* modal  */
.ant-modal-content {
    background-color: white !important;
}

.ant-modal-header {
    background-color: white !important;
}

.search>.ant-btn {
    border-radius: 10px !important;
    height: 40px !important;
}