.table-white .ant-table-placeholder {
    background: white !important;
    border-radius: 15px !important;
    box-shadow: none !important;
}

.table-white .ant-table-thead {
    background: white !important;
    border-radius: 15px !important;
    box-shadow: none !important;
}

.table-white .ant-table-tbody {
    background: white !important;
    border-radius: 15px !important;
    box-shadow: none !important;
}

.table-white .ant-table-cell {
    background: white !important;
}

.valid-tag {
    font-size: 14px;
    font-weight: 600;
    background: rgb(0, 100, 0);
    padding: 6px 10px;
    color: white;
    border-radius: 24px;
    margin-right: 10px;
}

.invalid-tag {
    font-size: 14px;
    font-weight: 600;
    background: red;
    padding: 6px 10px;
    color: white;
    border-radius: 24px;
}