input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #000;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #fff;
}

.login-box input:-webkit-autofill,
.login-box input:-webkit-autofill:hover,
.login-box input:-webkit-autofill:focus,
.login-box input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #000;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #f5f5f5;
}

.login-box .ant-input {
  background-color: #f5f5f5;
}

/* override ant d css */
.ant-menu-item {
  width: 100% !important;
  height: 55px !important;
  border-radius: 0 !important;
  padding-left: 15px !important;
  padding-right: 24px !important;
  margin-left: 0 !important;
  margin-right: 0px !important;
}

.ant-menu-item-selected {
  background-color: #c2002f !important;
  margin-left: 0 !important;
  margin-right: 0px !important;
}

.sidebar-item {
  padding-right: 0px !important;
}

.sidebar-item :hover {
  margin: 0 !important;
}

.ant-menu-item-selected .sidebar-text {
  font-weight: 500;
  color: white;
}

.sidebar-text {
  margin-left: 10px;
  font-weight: 500;
  color: gray;
}

.sidebar-icon {
  color: gray !important;
}

.custom-side-bar {
  height: '100vh';
  position: fixed !important;
  left: 0px !important;
  top: 70px !important;
  bottom: 0 !important;
  background: #f1f1f1 !important;
  overflow: auto;
}

.custom-content {
  position: fixed !important;
  top: 60px !important;
  left: 240px;
  right: 0px !important;
  bottom: 0px !important;
  padding: 50px 0px 24px 0px !important;
  margin: 0px !important;
  overflow: scroll !important;
  background: white !important;
}

.sidebar-open {
  left: 0;
}

.upload-directory {
  margin-top: 10px;
}

.upload-directory:hover{
  background-color: #f1f1f1;
}

/* Media query for small screens (mobile devices) */
@media screen and (max-width: 768px) {
  .logo-center {
    text-align: center;
  }

  .custom-side-bar {
    left: 0 !important;
    right: 0 !important;
    background-color: #c2002f !important;
  }

  .custom-content {
    left: 0 !important;
  }
}

.ant-select-selection-search-input {
  height: 40px;
}

.ant-btn {
  /* border-radius: 30px !important; */
  border-radius: 9px !important;
}

.custom-btn .ant-btn {
  border-radius: 10px !important;
}

.ant-input-number {
  font-size: 14px !important;
  border: none !important;
  border-radius: 9px !important;
}

.ant-input-number :focus {
  border-radius: 9px !important;
  border-color: black !important;
  box-shadow: 0 0 0 0.5px gray !important;
}

.ant-input {
  height: 40px;
  border: none !important;
  border-radius: 11px !important;
}

.ant-input:focus {
  border-radius: 9px !important;
  box-shadow: 0 0 0 1.5px gray !important;
}

.ant-picker-input:focus {
  border: none !important;
}

.ant-picker-focused {
  box-shadow: none;
}

.ant-input-password {
  border: none !important;
  border-radius: 9px !important;
}

.ant-input-affix-wrapper-focused {
  border-radius: 9px !important;
  box-shadow: 0 0 0 1.5px gray !important;
}

.ant-picker {
  border: none !important;
  border-radius: 9px !important;
  font-size: 14px !important;
}

.custom-date-picker :focus {
  border-color: black !important;
  /* box-shadow: 0 0 0 1.5px gray !important; */
  outline: 0;
  border-inline-end-width: 1px;

}

/* modal  */
.ant-modal-content {
  background-color: #f5f5f5 !important;
}

.ant-modal-header {
  background-color: #f5f5f5 !important;
}

/* select */
.ant-select-selector {
  border: none !important;
  box-shadow: none;
}

.ant-switch.ant-switch-checked {
  background-color: green !important;
}

.custom-table .ant-table-placeholder {
  background: #f99d9d !important;
  border-radius: 15px !important;
  box-shadow: none !important;
}

.custom-table .ant-table-thead {
  background: #f5f5f5 !important;
  border-radius: 15px !important;
  box-shadow: none !important;
}

.custom-table .ant-table-tbody {
  background: #f5f5f5 !important;
  border-radius: 15px !important;
  box-shadow: none !important;
}

.custom-table .ant-table-cell {
  background: #f5f5f5 !important;
}

.siber-header {
  display: flex;
  align-items: center;
  padding-left: 15px;
  color: #c2002f;
  font-size: 12px;
  font-weight: 700;
  background-color: lightgray;
  height: 30px;

}

.custom-selector-gray>.ant-select-selector {
  border: none !important;
  background-color: #f1f1f1 !important;
  box-shadow: none !important;
}

.custom-selector-white>.ant-select-selector {
  border: none !important;
  background-color: #f1f1f1 !important;
  box-shadow: none !important;
}


.ant-select-open {
  border-color: #c2002f !important;
  box-shadow: none;
}

.blue-row {
  background-color: #f1f1f1;
  /* Blue color */
}

.black-row {
  background-color: white;
  /* Black color */
}

.step-full-width-title .ant-steps-item-title {
  width: 100% !important;
}


/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #c2002f;
  border-radius: 10px;

}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background-color: black;
} */


/* MapBox  */
.mapboxgl-marker {
  color: #ff0000;
  /* Change the default marker color to red */
}

.table-row-pointer {
  cursor: pointer;
}

.card-shadow {
  box-shadow: 0px 4px 20px #ead7d7 !important;
  border-radius: 15px;
}

.ant-card-head {
  background-color: #f1f1f1 !important;
}

/* Basic styling for the stepper container */
.stepper {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-left: 20px;
  position: relative;
}

/* Vertical line for the steps */
.stepper::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 15px;
  width: 2px;
  background-color: black;
}

/* Styling each step */
.step {
  display: flex;
  align-items: start;
  margin-bottom: 20px;
  position: relative;
}

/* Circle for the step number */
.step-number {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #007bff;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  margin-right: 10px;
  z-index: 1;
}

/* Step content */
.step-content {
  padding-left: 10px;
}

.step-content h3 {
  margin: 0;
  font-size: 18px;
}

.step-content p {
  margin: 5px 0 0;
  font-size: 14px;
}

/* Modal */
/* .modalContent {
  position: relative; 
  display: inline-block;
  width: 100%;
  height: 100%;
  padding: 10px;
  background: #fff;
}

.modalContent .ant-modal-mask,
.modalContent .ant-modal-wrap {
  position: absolute;
} */

