.map-container {
  position: relative;
  height: 90vh;
  width: 100%;
  margin: 0 auto;
}

.loading-layer {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(208, 208, 208, 0.8);
  font-size: 1.5em;
  color: #333;
}
